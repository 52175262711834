const reducer = (state, action) => {
    switch (action.type) {
    case 'SET_LANGUAGE':
        return {
            ...state,
            language: action.payload,
        };
    case 'TOGGLE_MODAL':
        return {
            ...state,
            [action.type]: {
                content: action.payload,
            },
        };
    default:
        return state;
    }
};

export default reducer;
