exports.components = {
  "component---src-components-blog-router-blog-router-jsx": () => import("./../../../src/components/BlogRouter/BlogRouter.jsx" /* webpackChunkName: "component---src-components-blog-router-blog-router-jsx" */),
  "component---src-components-order-router-order-router-jsx": () => import("./../../../src/components/OrderRouter/OrderRouter.jsx" /* webpackChunkName: "component---src-components-order-router-order-router-jsx" */),
  "component---src-components-product-router-product-router-jsx": () => import("./../../../src/components/ProductRouter/ProductRouter.jsx" /* webpackChunkName: "component---src-components-product-router-product-router-jsx" */),
  "component---src-pages-index-page-index-page-jsx": () => import("./../../../src/pages/IndexPage/IndexPage.jsx" /* webpackChunkName: "component---src-pages-index-page-index-page-jsx" */),
  "component---src-pages-order-success-order-success-jsx": () => import("./../../../src/pages/OrderSuccess/OrderSuccess.jsx" /* webpackChunkName: "component---src-pages-order-success-order-success-jsx" */),
  "component---src-pages-page-not-found-page-not-found-jsx": () => import("./../../../src/pages/PageNotFound/PageNotFound.jsx" /* webpackChunkName: "component---src-pages-page-not-found-page-not-found-jsx" */),
  "component---src-pages-privacy-page-privacy-page-jsx": () => import("./../../../src/pages/PrivacyPage/PrivacyPage.jsx" /* webpackChunkName: "component---src-pages-privacy-page-privacy-page-jsx" */),
  "component---src-pages-terms-page-terms-page-jsx": () => import("./../../../src/pages/TermsPage/TermsPage.jsx" /* webpackChunkName: "component---src-pages-terms-page-terms-page-jsx" */),
  "component---src-pages-track-order-track-order-jsx": () => import("./../../../src/pages/TrackOrder/TrackOrder.jsx" /* webpackChunkName: "component---src-pages-track-order-track-order-jsx" */)
}

