import { useReducer, createContext } from 'react';
import reducer from './reducer';

export const initialState = {
    language: null,
    modal: {},
};

export const Context = createContext({
    state: initialState,
    dispatch: () => null,
});

const Provider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    return <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>;
};

export default Provider;
